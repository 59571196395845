export const LIST_OF_RIGHTS_GROUPS = ['smc', 'see', 'spr', 'sbs', 'tts', 'qas', 'ses', 'res', 'auth', 'model', 'corpus', 'marks', 'transcription', 'server', 'lang'];

export const USER = {
	RIGHTS_LIST: 'GET:/auth/rights', // должен быть доступен всегда по access-токену
	ACCESS: 'POST:/auth/access', // доступен без токенов
	REFRESH: 'GET:/auth/refresh', // доступен по refresh-токену
	PASSWORD: 'POST:/auth/user/password',
	CURRENT_USER_INFO: 'GET:/auth/user/info', // должен быть доступен всегда по access-токену
	LIST: 'GET:/auth/user/list',
	USER_INFO: 'GET:/auth/user/get',
	ADD: 'POST:/auth/user/add',
	MODIFY: 'POST:/auth/user/modify',
	DELETE: 'DELETE:/auth/user/delete',
	VERSION: 'GET:/auth/version',
	LOGS: 'POST:/auth/userlog',
};

export const ROLE = {
	LIST: 'GET:/auth/roles', // должен быть доступен всегда по access-токену
	ADD: 'POST:/auth/role/add',
	EDIT: 'POST:/auth/role/modify',
	DELETE: 'DELETE:/auth/role/delete',
};

export const DATASET = {
	LIST: 'GET:/auth/datasets',
	ADD: 'POST:/auth/dataset/add',
	EDIT: 'POST:/auth/dataset/modify',
	DELETE: 'DELETE:/auth/dataset/delete',
};

export const MODEL = {
	INFO: 'GET:/model/info',
	IMPORT: 'POST:/model/import',
	EXPORT: 'GET:/model/export',
	ADD: 'POST:/model/add',
	INSTALL: 'POST:/model/install',
	APPLY: 'POST:/model/apply',
	RESTORE: 'POST:/model/restore',
	LOGS: 'GET:/model/log',
	ERRORS: 'GET:/model/errors',
	DELETE: 'DELETE:/model/delete',
};

export const HANDLER = {
	GET: 'GET:/model/handler',
	SAVE: 'POST:/model/handler',
	DELETE: 'DELETE:/model/handler',
};

export const CORPUS = {
	LIST: 'GET:/corpus/list',
	INFO: 'GET:/corpus/get',
	IMPORT: 'POST:/corpus/import',
	EXPORT: 'GET:/corpus/export',
	COPY: 'POST:/corpus/copy',
	SAVE: 'POST:/corpus/put',
	RENAME: 'POST:/corpus/rename',
	DELETE: 'DELETE:/corpus/delete',

	AUTOMARK_START: 'GET:/corpus/automark/start',
	AUTOMARK_STOP: 'GET:/corpus/automark/stop',
	AUTOMARK_STATUS: 'GET:/corpus/automark/status',
	AUTOMARK_LIST: 'GET:/corpus/automark/list',
	AUTOMARK_RESULT: 'GET:/corpus/automark/result',
};

export const MARKS = {
	LIST: 'GET:/marks/list',
	GET: 'GET:/marks/get',
	ADD: 'POST:/marks/add',
	IMPORT: 'POST:/marks/import',
	EXPORT: 'GET:/marks/export',
	PUT: 'POST:/marks/put',
	RENAME: 'POST:/marks/rename',
	DELETE: 'DELETE:/marks/delete',
};

export const SERVER = {
	ADDRESSES: 'GET:/server/get/addresses',
	MODEL_LIST: 'GET:/server/get/models',
};

export const SMC = {
	TRAIN: 'POST:/smc/train',
	STOP_TRAIN: 'POST:/smc/stop',
	UPDATE_GROUPS: 'POST:/smc/groups',
	TEST: 'POST:/smc/test',
	CLASSIFY: 'GET:/smc/classify',
	COMPRESS: 'GET:/smc/compress',
	EMOTION: 'GET:/smc/emotion',
	PUNCTUATE: 'GET:/smc/punctuate',
	CORRECT: 'GET:/smc/correct',
	NORMALIZE: 'GET:/smc/normalize',
	TOXICITY: 'GET:/smc/toxicity',
};

export const SEE = {
	TRAIN: 'POST:/see/train',
	STOP_TRAIN: 'POST:/see/stop',
	SEARCH_ENTITIES: 'GET:/see/entities',
};

export const SPR = {
	RECOGNITION: 'POST:/spr/stt',
	ASYNC_RESULT: 'GET:/spr/result',
	DELETE_TASK: 'DELETE:/spr/queue',
	QUEUE: 'GET:/spr/queue',
	AUDIO: 'GET:/spr/audio',
	WAVEFORM: 'GET:/spr/waveform',
};

export const TRANSCRIPTION = {
	LIST: 'GET:/transcription/list',
	GET_DATA: 'GET:/transcription/get',
	GET_AUDIO: 'GET:/transcription/audio',
	WAVEFORM: 'GET:/transcription/waveform',
	CREATE: 'POST:/transcription/create',
	MODIFY: 'POST:/transcription/modify',
	SAVE: 'POST:/transcription/save',
	DELETE: 'DELETE:/transcription/delete',
	METRICS: 'GET:/transcription/errors'
};

export const SBS = {
	SPEAKERS: 'GET:/sbs/speakers/list',
	SPEAKER_ADD: 'POST:/sbs/speakers/add',
	SPEAKER_EDIT: 'POST:/sbs/speakers/modify',
	SPEAKER_DELETE: 'DELETE:/sbs/speakers/delete',
	ANALYZE: 'POST:/sbs/analyze',
	SEARCH: 'POST:/sbs/search',
	VERIFY: 'POST:/sbs/verify',
};

export const TTS = {
	SYNTHESIS: 'GET:/tts/synthesize',
	DICTIONARY_GET: 'GET:/tts/dictionary/get',
	DICTIONARY_IMPORT: 'POST:/tts/dictionary/import',
	DICTIONARY_EXPORT: 'GET:/tts/dictionary/export',
	DICTIONARY_SAVE: 'POST:/tts/dictionary/put',
	NORMALIZE: 'POST:/tts/normalize',
	REVOICERS_LIST: 'GET:/tts/revoicers',
	REVOICE: 'POST:/tts/revoice',
};

export const QAS = {
	SEARCH: 'GET:/qas/search',
	ASK: 'GET:/qas/ask',

	DOC_LIST: 'GET:/qas/doc/list',
	DOC_GET: 'GET:/qas/doc/get',
	DOC_ADD: 'POST:/qas/doc/add',
	DOC_EDIT: 'POST:/qas/doc/modify',
	DOC_DELETE: 'DELETE:/qas/doc/delete',

	QUESTION_LIST: 'GET:/qas/question/list',
	QUESTION_GET: 'GET:/qas/question/get',
	QUESTION_ADD: 'POST:/qas/question/add',
	QUESTION_EDIT: 'POST:/qas/question/modify',
	QUESTION_DELETE: 'DELETE:/qas/question/delete',
	QUESTION_LIST_DELETE: 'POST:/qas/question/delete/list',
	QUESTION_APPEND: 'POST:/qas/question/append',
	QUESTION_APPROVE: 'POST:/qas/question/approve',

	CATEGORY_LIST: 'GET:/qas/category/list',
	CATEGORY_ADD: 'POST:/qas/category/add',
	CATEGORY_EDIT: 'POST:/qas/category/modify',
	CATEGORY_DELETE: 'DELETE:/qas/category/delete',

	CHANNEL_LIST: 'GET:/qas/channel/list',
	CHANNEL_ADD: 'POST:/qas/channel/add',
	CHANNEL_EDIT: 'POST:/qas/channel/modify',
	CHANNEL_DELETE: 'DELETE:/qas/channel/delete',

	ANSWER_MAKER_START: 'GET:/qas/cachemaker/start',
	ANSWER_MAKER_STOP: 'GET:/qas/cachemaker/stop',
	ANSWER_MAKER_STATUS: 'GET:/qas/cachemaker/status',

	QUESTION_MAKER_START: 'GET:/qas/questionmaker/start',
	QUESTION_MAKER_STOP: 'GET:/qas/questionmaker/stop',
	QUESTION_MAKER_STATUS: 'GET:/qas/questionmaker/status',

	PROCESSING_QUESTIONS_START: 'POST:/qas/questionlist/start',
	PROCESSING_QUESTIONS_STOP: 'GET:/qas/questionlist/stop',
	PROCESSING_QUESTIONS_STATUS: 'GET:/qas/questionlist/status',
	PROCESSING_QUESTIONS_LOG: 'GET:/qas/questionlist/answers',
};

export const SES = {
	ROBOT_LIST: 'GET:/ses/robot/list',
	ROBOT_GET: 'GET:/ses/robot/get',
	ROBOT_ADD: 'POST:/ses/robot/add',
	ROBOT_EDIT: 'POST:/ses/robot/modify',
	ROBOT_DELETE: 'DELETE:/ses/robot/delete',
	ROBOT_COMMIT_CONFIG: 'GET:/ses/robot/commit',
	ROBOT_APPLY: 'GET:/ses/robot/apply',
	ROBOT_RESTORE: 'GET:/ses/robot/restore',
	ROBOT_CLEAR_DRAFT: 'GET:/ses/robot/cleardraft',
	ROBOT_IMPORT: 'POST:/ses/robot/import',
	ROBOT_EXPORT: 'GET:/ses/robot/export',

	DATA_LIST: 'GET:/ses/robot/data/list',
	DATA_GET: 'GET:/ses/robot/data/get',
	DATA_ADD: 'POST:/ses/robot/data/add',
	DATA_EDIT: 'POST:/ses/robot/data/modify',
	DATA_DELETE: 'DELETE:/ses/robot/data/delete',

	ENDPOINT_LIST: 'GET:/ses/robot/endpoint/list',
	ENDPOINT_GET: 'GET:/ses/robot/endpoint/get',
	ENDPOINT_ADD: 'POST:/ses/robot/endpoint/add',
	ENDPOINT_EDIT: 'POST:/ses/robot/endpoint/modify',
	ENDPOINT_DELETE: 'DELETE:/ses/robot/endpoint/delete',

	SCRIPT_LIST: 'GET:/ses/robot/script/list',
	SCRIPT_GET: 'GET:/ses/robot/script/get',
	SCRIPT_ADD: 'POST:/ses/robot/script/add',
	SCRIPT_EDIT: 'POST:/ses/robot/script/modify',
	SCRIPT_DELETE: 'DELETE:/ses/robot/script/delete',

	ASK: 'POST:/ses/ask',
	SESSION: 'GET:/ses/session',
};

export const RES = {
	DASHBOARDS: 'GET:/res/dashboards',
};

export const LANG = {
	LANG: 'GET:/lang/', // доступен без токенов
	WALLPAPER: 'GET:/lang/wallpaper', // доступен без токенов
};

export const SERVICE = {
	SMC: 'SERVICE:smc',
	SEE: 'SERVICE:see',
	SPR: 'SERVICE:spr',
	SBS: 'SERVICE:sbs',
	TTS: 'SERVICE:tts',
	QAS: 'SERVICE:qas',
	SES: 'SERVICE:ses',
	RES: 'SERVICE:res',
	AUTH: 'SERVICE:auth',
};
